import { render, staticRenderFns } from "./Error.vue?vue&type=template&id=6367a18b&scoped=true&functional=true"
import script from "./Error.vue?vue&type=script&lang=ts"
export * from "./Error.vue?vue&type=script&lang=ts"
import style0 from "./Error.vue?vue&type=style&index=0&id=6367a18b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "6367a18b",
  null
  
)

export default component.exports